@tailwind components;
@tailwind utilities;
@font-face {
    font-family: 'HKGrotesk';
    src: url(./assets/fonts/HKGrotesk-Regular.otf) format('opentype');
    font-style: normal;
    font-weight: 100;
}

@font-face {
    font-family: 'HKGrotesk';
    src: url(./assets/fonts/HKGrotesk-Medium.otf) format('opentype');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'HKGrotesk';
    src: url(./assets/fonts/HKGrotesk-SemiBold.otf) format('opentype');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'HKGrotesk';
    font-style: normal;
    src: url(./assets/fonts/HKGrotesk-Bold.otf) format('opentype');
    font-weight: 900;
}

@font-face {
    font-family: 'Poppins';
    src: url(assets/fonts/Poppins-Medium.ttf) format('opentype');
}
.font-poppins-medium {
    font-family: 'Poppins', sans-serif !important;
}

@font-face {
    font-family: 'Poppins-bold';
    src: url(assets/fonts/Poppins-Bold.ttf) format('opentype');
}
.font-poppins-bold {
    font-family: 'Poppins-bold', sans-serif !important;
}

@font-face {
    font-family: 'Poppins-regular';
    src: url(assets/fonts/Poppins-Regular.ttf) format('opentype');
}
.font-poppins-regular {
    font-family: 'Poppins-regular', sans-serif !important;
}

@font-face {
    font-family: 'Merri-bold';
    src: url(assets/fonts/MerriweatherSans-ExtraBold.ttf) format('opentype');
}
.font-merri-bold {
    font-family: 'Merri-bold', sans-serif !important;
}

@font-face {
    font-family: 'Merri-regular';
    src: url(assets/fonts/MerriweatherSans-Bold.ttf) format('opentype');
}
.font-merri-regular {
    font-family: 'Merri-regular', sans-serif !important;
}

* {
    font-family: 'Poppins-regular', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
:root {
    line-height: 1.5;
    font-weight: 400;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;

    .condition_list_title {
        position: absolute;
        background-color: white;
        border: 1px solid black;
        padding: 5px;
        display: none;
        min-width: fit-content;
        bottom: 0;
        border-radius: 5px;
        left: 100px;
        max-height: 150px;
        overflow: auto;
    }

    .condition_list_title_container:hover .condition_list_title {
        display: block;
    }
}

a {
    text-decoration: none;
}
::-webkit-scrollbar {
    height: 5px;
    width: 4px;
}

::-webkit-scrollbar-thumb {
    background: #e3e3e3;
    border-radius: 8px;
    width: 1px;
}

::-webkit-scrollbar-track {
    border-radius: 8px;
}

::-webkit-scrollbar-button {
    height: 300px;
}

::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background: #e3e3e3;
}

@media only screen and (max-height: 470px) {
    .dataTable {
        height: 250px;
    }
}
@media only screen and (max-height: 600px) {
    .actionModal {
        max-height: 400px;
        overflow: scroll;
    }
}
.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;

    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    text-align: center;
    border-radius: 6px;
    padding: 15px;

    /* Position the tooltip */
    position: absolute;
    z-index: 2000;
    left: 0;
    bottom: 15px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.calenderIcon[type='date']::-webkit-calendar-picker-indicator {
    background-size: contain;
    transform: scale(1.4);
    background-image: url('assets/images/calendar.svg');
}
.chatra--webkit {
    display: none !important;
}

#chatra[class~='chatra--expanded'] {
    display: block !important;
}

.react-flow__node-custom > div {
    word-wrap: break-word !important;
    word-break: break-all !important;
    padding: 0 27px !important;
}

.connectablestart[data-nodeid='0'] {
    opacity: 0;
    position: absolute;
    right: -2px;
    width: 0;
    height: 0;
}
.flatpickr-monthSelect-month + .selected {
    background-color: #2e672f !important;
    border: #2e672f !important;
}
